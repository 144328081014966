<template>
  <div class="samc-statistical-analysis">
    <div class="up">
      <div class="up-left">
        <div class="server-count-stat">
          <div class="server-item">
            <img src="imgs/server/organize.png" alt="" />
            <div>
              <p class="num" style="color: #5480f9">
                {{ serverCompany.count }}
              </p>
              <p>服务组织(家)</p>
            </div>
          </div>
          <div class="server-item">
            <img src="imgs/server/demand.png" alt="" />
            <div>
              <p class="num" style="color: #9c54f9">{{ serverDemand.count }}</p>
              <p>需求服务(条)</p>
            </div>
          </div>
          <div class="server-item">
            <img src="imgs/server/supply.png" alt="" />
            <div>
              <p class="num" style="color: #54b7f9">{{ serverSupply.count }}</p>
              <p>供应服务(条)</p>
            </div>
          </div>
          <div class="server-item">
            <img src="imgs/server/transport_server.png" alt="" />
            <div>
              <p class="num" style="color: #cd54f9">
                {{ serverTransport.count }}
              </p>
              <p>转运服务(条)</p>
            </div>
          </div>
        </div>
        <div class="s-a-d-change">
          <div class="head-box">
            <p class="title">转运单变化曲线</p>
            <div class="filter-box">
              <span
                :class="[
                  'filter-item',
                  SADType == 'day' ? 'filter-item-active' : '',
                ]"
                @click="() => SADCut('day')"
                >今日</span
              >
              <span
                :class="[
                  'filter-item',
                  SADType == 'week' ? 'filter-item-active' : '',
                ]"
                @click="() => SADCut('week')"
                >本周</span
              >
              <span
                :class="[
                  'filter-item',
                  SADType == 'month' ? 'filter-item-active' : '',
                ]"
                @click="() => SADCut('month')"
                >本月</span
              >
              <span
                :class="[
                  'filter-item',
                  SADType == 'year' ? 'filter-item-active' : '',
                ]"
                @click="() => SADCut('year')"
                >本年</span
              >
              <DatePicker
                type="daterange"
                style="width: 200px"
                v-model="SADDate"
              ></DatePicker>
            </div>
          </div>
          <div class="chart" ref="chart"></div>
        </div>
      </div>
      <!-- <div class="up-right">
        <p class="title">在线撮合</p>
        <div class="match-up" v-for="(item, index) in 7" :key="index">
          <img src="imgs/logo.png" alt="" />
          <div class="match-up-info-box">
            <p class="user">王宝（荣昌XXXX养殖企业）</p>
            <p class="msg">
              <span class="msg-cont">谢谢，合作愉快！</span>
              <span class="msg-time">2021-06-03 11:22</span>
            </p>
          </div>
        </div>
      </div> -->
    </div>
    <div class="down">
      <div class="down-item">
        <p class="title">最新需求</p>
        <div class="list-box">
          <div class="list-item" v-for="(item, index) in newDemand" :key="index">
            <p class="info">
              <span class="label" style="color: #06baa1">需</span>
              <span>{{item.demandName}}</span>
            </p>
            <p class="detail">
              <span>{{item.demandAddress}}</span>
              <span>{{item.createTime}}</span>
            </p>
          </div>
          <div class="no-data" v-if="newDemand.length == 0">
            <img src="imgs/empty/empty.png" alt="">
            <p>暂无需求信息</p>
          </div>
        </div>
      </div>
      <div class="down-item">
        <p class="title">最新供应</p>
        <div class="list-box">
          <div class="list-item" v-for="(item, index) in newSupply" :key="index">
            <p class="info">
              <span class="label" style="color: #ff3f6b">供</span>
              <span>{{item.demandName}}</span>
            </p>
            <p class="detail">
              <span>{{item.demandAddress}}</span>
              <span>{{item.createTime}}</span>
            </p>
          </div>
          <div class="no-data" v-if="newSupply.length == 0">
            <img src="imgs/empty/empty.png" alt="">
            <p>暂无供应信息</p>
          </div>
        </div>
      </div>
      <div class="down-item">
        <p class="title">最新转运</p>
        <div class="transport-list">
          <div class="transport-item" v-for="(item, index) in newTransport" :key="index">
            <div class="transport-cont">
              <span>荣昌XXXX生猪养殖基地</span>
              <span class="to">转运</span>
              <span>荣昌XXXX水果种植基地</span>
            </div>
            <p class="transport-time">2021-06-03 11:22</p>
          </div>
          <div class="no-data" v-if="newTransport.length == 0">
            <img src="imgs/empty/empty.png" alt="">
            <p>暂无转运信息</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
export default {
  name: "",
  data() {
    return {
      SADDate: [],
      SADType: "year",
      sadChart: null,
      serverCompany: {},
      serverDemand: {},
      serverSupply: {},
      serverTransport: {},
      SADData: [],
      newDemand: [],
      newSupply: [],
      newTransport: []
    };
  },
  watch: {
    SADDate(n) {
      if (!n[0]) return;
      this.SADType = "custom";
      this.getSADChange();
    },
  },
  methods: {
    getServerCompany() {
      this.$post(this.$api.STATICTICS_ANALYSIS.SERVER_COMPANY).then((res) => {
        this.serverCompany = res;
      });
    },
    getServerDemand() {
      this.$post(this.$api.STATICTICS_ANALYSIS.SERVER_DEMAND).then((res) => {
        this.serverDemand = res;
      });
    },
    getServerSupply() {
      this.$post(this.$api.STATICTICS_ANALYSIS.SERVER_SUPPLY).then((res) => {
        this.serverSupply = res;
      });
    },
    getServerTransport() {
      this.$post(this.$api.STATICTICS_ANALYSIS.SERVER_TRANSPORT).then((res) => {
        this.serverTransport = res;
      });
    },
    getNewDemand() {
      this.$post(this.$api.SUPPLY_BIND.LIST, {
        demandType: '1',
        demandStatus: '1',
        pageNo: 1,
        pageSize: 5
      }).then(res => {
        this.newDemand = res.list
      })
    },
    getNewSupply() {
      this.$post(this.$api.SUPPLY_BIND.LIST, {
        demandType: '0',
        demandStatus: '1',
        pageNo: 1,
        pageSize: 5
      }).then(res => {
        this.newSupply = res.list
      })
    },
    getNewTransport() {
      this.$post(this.$api.TRANSPORT.LIST, {
        pageNo: 1,
        pageSize: 5
      }).then(res => {
        this.newTransport = res.list
      })
    },
    SADCut(type) {
      if (this.SADType == type) return
      this.SADType = type
      this.getSADChange()
    },
    getSADChange() {
      this.$post(this.$api.STATICTICS_ANALYSIS.S_A_D_CHANGE, {
        type: this.SADType,
        startTime:
          this.SADType == "custom"
            ? moment(this.SADDate[0]).format("YYYY-MM-DD HH:mm:ss")
            : "",
        endTime:
          this.SADType == "custom"
            ? moment(this.SADDate[1]).format("YYYY-MM-DD HH:mm:ss")
            : "",
      }).then(res => {
        this.SADData = res
        this.initSADChart()
      })
    },
    initSADChart() {
      let xData = []
      let orderData = []
      let transportData = []
      let orderList = Object.keys(this.SADData.orderInfo).sort((a, b) => {
        let tempA = a.replace(/-/g, '').replace(/\s*/g, '')
        let tempB = b.replace(/-/g, '').replace(/\s*/g, '')
        return +tempA - +tempB
      })
      let transportList = Object.keys(this.SADData.transport).sort((a, b) => {
        let tempA = a.replace(/-/g, '').replace(/\s*/g, '')
        let tempB = b.replace(/-/g, '').replace(/\s*/g, '')
        return +tempA - +tempB
      })
      for (let i = 0; i < orderList.length; i++) {
        let key = this.SADData.orderInfo[orderList[i]];
        xData.push(orderList[i]);
        orderData.push(key);
      }
      for (let i = 0; i < transportList.length; i++) {
        let key = this.SADData.transport[transportList[i]];
        transportData.push(key);
      }
      if (!this.sadChart) {
        this.sadChart = echarts.init(this.$refs.chart);
      }
      let option = {
        grid: {
          top: 20,
          left: 50,
          right: 50,
          bottom: 20,
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [{ name: "供需" }, { name: "转运" }],
        },
        xAxis: {
          data: xData,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEE8E8",
              type: "dashed",
            },
          },
          boundaryGap: false,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f6f6f6",
            },
          },
        },
        color: ["#7A40F2", "#CD54F9"],
        series: [
          {
            type: "line",
            name: "供需",
            smooth: true,
            data: orderData,
            lineStyle: {
              shadowOffsetY: 10,
              shadowColor: "rgba(122, 64, 242, 1)",
              shadowBlur: 20,
            },
          },
          {
            type: "line",
            name: "转运",
            smooth: true,
            data: transportData,
            lineStyle: {
              shadowOffsetY: 10,
              shadowColor: "rgba(205, 84, 249, 1)",
              shadowBlur: 20,
            },
          },
        ],
      };
      this.sadChart.setOption(option);
    },
    resize() {
      this.sadChart.resize();
    },
  },
  mounted() {
    this.getServerCompany();
    this.getServerDemand();
    this.getServerSupply();
    this.getServerTransport();
    this.getSADChange();
    this.getNewDemand()
    this.getNewSupply()
    this.getNewTransport()
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>